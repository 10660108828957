export let groupedBookingData = [
  // {
  //   "months": [
      // {
      //   "month": "July",
      //   "monthFR": "Juillet",
      //   "bookings": [
      //     {
      //       "_id": "67016b2d831c71f5a2732fec",
      //       "property": "Oleron Villa",
      //       "start": "2024-07-06T00:00:00.000Z",
      //       "end": "2024-07-13T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Fred West",
      //         "email": "fred@west.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 7
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732fed",
      //       "property": "Oleron Villa",
      //       "start": "2024-07-13T00:00:00.000Z",
      //       "end": "2024-07-20T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 2",
      //         "email": "booking2@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 7
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732fee",
      //       "property": "Oleron Villa",
      //       "start": "2024-07-20T00:00:00.000Z",
      //       "end": "2024-07-27T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 3",
      //         "email": "booking3@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 7
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732fef",
      //       "property": "Oleron Villa",
      //       "start": "2024-07-27T00:00:00.000Z",
      //       "end": "2024-08-03T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 4",
      //         "email": "booking4@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 7
      //     }
      //   ]
      // },
      // {
      //   "month": "August",
      //   "monthFR": "Août",
      //   "bookings": [
      //     {
      //       "_id": "67016b2d831c71f5a2732ff0",
      //       "property": "Oleron Villa",
      //       "start": "2024-08-03T00:00:00.000Z",
      //       "end": "2024-08-10T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 5",
      //         "email": "booking5@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 8
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff1",
      //       "property": "Oleron Villa",
      //       "start": "2024-08-10T00:00:00.000Z",
      //       "end": "2024-08-17T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 6",
      //         "email": "booking6@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 8
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff2",
      //       "property": "Oleron Villa",
      //       "start": "2024-08-17T00:00:00.000Z",
      //       "end": "2024-08-24T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 7",
      //         "email": "booking7@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 8
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff3",
      //       "property": "Oleron Villa",
      //       "start": "2024-08-24T00:00:00.000Z",
      //       "end": "2024-08-31T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 8",
      //         "email": "booking8@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 8
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff4",
      //       "property": "Oleron Villa",
      //       "start": "2024-08-31T00:00:00.000Z",
      //       "end": "2024-09-07T00:00:00.000Z",
      //       "booked": true,
      //       "booking": {
      //         "name": "Booking 9",
      //         "email": "booking9@example.com",
      //         "phone": "+44-87342282797",
      //         "comments": "Test booking",
      //         "source": "Direct"
      //       },
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 8
      //     }
      //   ]
      // },
      // {
      //   "month": "September",
      //   "monthFR": "Septembre",
      //   "bookings": [
      //     {
      //       "_id": "67016b2d831c71f5a2732ff5",
      //       "property": "Oleron Villa",
      //       "start": "2024-09-07T00:00:00.000Z",
      //       "end": "2024-09-14T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 9
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff6",
      //       "property": "Oleron Villa",
      //       "start": "2024-09-14T00:00:00.000Z",
      //       "end": "2024-09-21T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 9
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff7",
      //       "property": "Oleron Villa",
      //       "start": "2024-09-21T00:00:00.000Z",
      //       "end": "2024-09-28T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 9
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ff8",
      //       "property": "Oleron Villa",
      //       "start": "2024-09-28T00:00:00.000Z",
      //       "end": "2024-10-05T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 9
      //     }
      //   ]
      // },
      // {
      //   "month": "October",
      //   "monthFR": "Octobre",
      //   "bookings": [
      //     {
      //       "_id": "67016b2d831c71f5a2732ff9",
      //       "property": "Oleron Villa",
      //       "start": "2024-10-05T00:00:00.000Z",
      //       "end": "2024-10-12T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 10
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ffa",
      //       "property": "Oleron Villa",
      //       "start": "2024-10-12T00:00:00.000Z",
      //       "end": "2024-10-19T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 10
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ffb",
      //       "property": "Oleron Villa",
      //       "start": "2024-10-19T00:00:00.000Z",
      //       "end": "2024-10-26T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 10
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ffc",
      //       "property": "Oleron Villa",
      //       "start": "2024-10-26T00:00:00.000Z",
      //       "end": "2024-11-02T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 10
      //     }
      //   ]
      // },
      // {
      //   "month": "November",
      //   "monthFR": "Novembre",
      //   "bookings": [
      //     {
      //       "_id": "67016b2d831c71f5a2732ffd",
      //       "property": "Oleron Villa",
      //       "start": "2024-11-02T00:00:00.000Z",
      //       "end": "2024-11-09T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 11
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732ffe",
      //       "property": "Oleron Villa",
      //       "start": "2024-11-09T00:00:00.000Z",
      //       "end": "2024-11-16T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 11
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2732fff",
      //       "property": "Oleron Villa",
      //       "start": "2024-11-16T00:00:00.000Z",
      //       "end": "2024-11-23T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 11
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2733000",
      //       "property": "Oleron Villa",
      //       "start": "2024-11-23T00:00:00.000Z",
      //       "end": "2024-11-30T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 11
      //     },
      //     {
      //       "_id": "67016b2d831c71f5a2733001",
      //       "property": "Oleron Villa",
      //       "start": "2024-11-30T00:00:00.000Z",
      //       "end": "2024-12-07T00:00:00.000Z",
      //       "booked": false,
      //       "price": 1200,
      //       "archived": false,
      //       "year": 2024,
      //       "month": 11
      //     }
      //   ]
      // },
  //     {
  //       "month": "December",
  //       "monthFR": "Décembre",
  //       "bookings": [
  //         {
  //           "_id": "67016b2d831c71f5a2733002",
  //           "property": "Oleron Villa",
  //           "start": "2024-12-07T00:00:00.000Z",
  //           "end": "2024-12-14T00:00:00.000Z",
  //           "booked": false,
  //           "price": 1200,
  //           "archived": false,
  //           "year": 2024,
  //           "month": 12
  //         },
  //         {
  //           "_id": "67016b2d831c71f5a2733003",
  //           "property": "Oleron Villa",
  //           "start": "2024-12-14T00:00:00.000Z",
  //           "end": "2024-12-21T00:00:00.000Z",
  //           "booked": false,
  //           "notes": "Abritel",
  //           "price": 1200,
  //           "archived": false,
  //           "year": 2024,
  //           "month": 12
  //         },
  //         {
  //           "_id": "67016b2d831c71f5a2733004",
  //           "property": "Oleron Villa",
  //           "start": "2024-12-21T00:00:00.000Z",
  //           "end": "2024-12-28T00:00:00.000Z",
  //           "booked": true,
  //           "notes": "Abritel",
  //           "price": 1200,
  //           "archived": false,
  //           "year": 2024,
  //           "month": 12
  //         },
  //         {
  //           "property": "Oleron Villa",
  //           "start": "2024-12-28T00:00:00.000Z",
  //           "end": "2024-01-04T00:00:00.000Z",
  //           "booked": true,
  //           "price": 1200,
  //           "archived": false,
  //           "year": 2024,
  //           "month": 12
  //         }
  //       ]
  //     }
  //   ],
  //   "year": 2024
  // },
  {
    "months": [
      {
        "month": "January",
        "monthFR": "Janvier",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733005",
            "property": "Oleron Villa",
            "start": "2025-01-04T00:00:00.000Z",
            "end": "2025-01-11T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 1
          },
          {
            "_id": "67016b2d831c71f5a2733006",
            "property": "Oleron Villa",
            "start": "2025-01-11T00:00:00.000Z",
            "end": "2025-01-18T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 1
          },
          {
            "_id": "67016b2d831c71f5a2733007",
            "property": "Oleron Villa",
            "start": "2025-01-18T00:00:00.000Z",
            "end": "2025-01-25T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 1
          },
          {
            "_id": "67016b2d831c71f5a2733008",
            "property": "Oleron Villa",
            "start": "2025-01-25T00:00:00.000Z",
            "end": "2025-02-01T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 1
          }
        ]
      },
      {
        "month": "February",
        "monthFR": "Février",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733009",
            "property": "Oleron Villa",
            "start": "2025-02-01T00:00:00.000Z",
            "end": "2025-02-08T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 2
          },
          {
            "_id": "67016b2d831c71f5a273300a",
            "property": "Oleron Villa",
            "start": "2025-02-08T00:00:00.000Z",
            "end": "2025-02-15T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 2
          },
          {
            "_id": "67016b2d831c71f5a273300b",
            "property": "Oleron Villa",
            "start": "2025-02-15T00:00:00.000Z",
            "end": "2025-02-22T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 2
          },
          {
            "_id": "67016b2d831c71f5a273300c",
            "property": "Oleron Villa",
            "start": "2025-02-22T00:00:00.000Z",
            "end": "2025-03-01T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 2
          }
        ]
      },
      {
        "month": "March",
        "monthFR": "Mars",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a273300d",
            "property": "Oleron Villa",
            "start": "2025-03-01T00:00:00.000Z",
            "end": "2025-03-08T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 3
          },
          {
            "_id": "67016b2d831c71f5a273300e",
            "property": "Oleron Villa",
            "start": "2025-03-08T00:00:00.000Z",
            "end": "2025-03-15T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 3
          },
          {
            "_id": "67016b2d831c71f5a273300f",
            "property": "Oleron Villa",
            "start": "2025-03-15T00:00:00.000Z",
            "end": "2025-03-22T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 3
          },
          {
            "_id": "67016b2d831c71f5a2733010",
            "property": "Oleron Villa",
            "start": "2025-03-22T00:00:00.000Z",
            "end": "2025-03-29T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 3
          },
          {
            "_id": "67016b2d831c71f5a2733011",
            "property": "Oleron Villa",
            "start": "2025-03-29T00:00:00.000Z",
            "end": "2025-04-05T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 3
          }
        ]
      },
      {
        "month": "April",
        "monthFR": "Avril",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733012",
            "property": "Oleron Villa",
            "start": "2025-04-05T00:00:00.000Z",
            "end": "2025-04-12T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 4
          },
          {
            "_id": "67016b2d831c71f5a2733013",
            "property": "Oleron Villa",
            "start": "2025-04-12T00:00:00.000Z",
            "end": "2025-04-19T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 4
          },
          {
            "_id": "67016b2d831c71f5a2733014",
            "property": "Oleron Villa",
            "start": "2025-04-19T00:00:00.000Z",
            "end": "2025-04-26T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 4
          },
          {
            "_id": "67016b2d831c71f5a2733015",
            "property": "Oleron Villa",
            "start": "2025-04-26T00:00:00.000Z",
            "end": "2025-05-03T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 4
          }
        ]
      },
      {
        "month": "May",
        "monthFR": "Mai",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733016",
            "property": "Oleron Villa",
            "start": "2025-05-03T00:00:00.000Z",
            "end": "2025-05-06T00:00:00.000Z",
            "booked": false,
            "price": 600,
            "archived": false,
            "year": 2025,
            "month": 5
          },
          {
            "_id": "67016b2d831c71f5a2733017",
            "property": "Oleron Villa",
            "start": "2025-05-06T00:00:00.000Z",
            "end": "2025-05-13T00:00:00.000Z",
            "booked": true,
            "notes": "Abritel",
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 5
          },
          {
            "_id": "67016b2d831c71f5a2733018",
            "property": "Oleron Villa",
            "start": "2025-05-13T00:00:00.000Z",
            "end": "2025-05-17T00:00:00.000Z",
            "booked": false,
            "price": 750,
            "archived": false,
            "year": 2025,
            "month": 5
          },
          {
            "_id": "67016b2d831c71f5a2733018",
            "property": "Oleron Villa",
            "start": "2025-05-17T00:00:00.000Z",
            "end": "2025-05-24T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 5
          },
          {
            "_id": "67016b2d831c71f5a2733019",
            "property": "Oleron Villa",
            "start": "2025-05-24T00:00:00.000Z",
            "end": "2025-05-31T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 5
          },
          {
            "_id": "67016b2d831c71f5a273301a",
            "property": "Oleron Villa",
            "start": "2025-05-31T00:00:00.000Z",
            "end": "2025-06-07T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 5
          }
        ]
      },
      {
        "month": "June",
        "monthFR": "Juin",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a273301b",
            "property": "Oleron Villa",
            "start": "2025-06-07T00:00:00.000Z",
            "end": "2025-06-14T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 6
          },
          {
            "_id": "67016b2d831c71f5a273301c",
            "property": "Oleron Villa",
            "start": "2025-06-14T00:00:00.000Z",
            "end": "2025-06-21T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 6
          },
          {
            "_id": "67016b2d831c71f5a273301d",
            "property": "Oleron Villa",
            "start": "2025-06-21T00:00:00.000Z",
            "end": "2025-06-28T00:00:00.000Z",
            "booked": false,
            "price": 1300,
            "archived": false,
            "year": 2025,
            "month": 6
          },
          {
            "_id": "67016b2d831c71f5a273301e",
            "property": "Oleron Villa",
            "start": "2025-06-28T00:00:00.000Z",
            "end": "2025-07-03T00:00:00.000Z",
            "booked": false,
            "price": 925,
            "archived": false,
            "year": 2025,
            "month": 6
          }
        ]
      },
      {
        "month": "July",
        "monthFR": "Juillet",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a273301f",
            "property": "Oleron Villa",
            "start": "2025-07-03T00:00:00.000Z",
            "end": "2025-07-10T00:00:00.000Z",
            "booked": true,
            "notes": "Abritel",
            "price": 1300,
            "archived": false,
            "year": 2025,
            "month": 7
          },
          {
            "_id": "67016b2d831c71f5a2733020",
            "property": "Oleron Villa",
            "start": "2025-07-10T00:00:00.000Z",
            "end": "2025-07-12T00:00:00.000Z",
            "booked": false,
            "price": 515,
            "archived": false,
            "year": 2025,
            "month": 7
          },
          {
            "_id": "67016b2d831c71f5a2733020",
            "property": "Oleron Villa",
            "start": "2025-07-12T00:00:00.000Z",
            "end": "2025-07-19T00:00:00.000Z",
            "booked": false,
            "price": 1800,
            "archived": false,
            "year": 2025,
            "month": 7
          },
          {
            "_id": "67016b2d831c71f5a2733021",
            "property": "Oleron Villa",
            "start": "2025-07-19T00:00:00.000Z",
            "end": "2025-07-26T00:00:00.000Z",
            "booked": false,
            "price": 1800,
            "archived": false,
            "year": 2025,
            "month": 7
          },
          {
            "_id": "67016b2d831c71f5a2733022",
            "property": "Oleron Villa",
            "start": "2025-07-26T00:00:00.000Z",
            "end": "2025-07-28T00:00:00.000Z",
            "booked": false,
            "price": 600,
            "archived": false,
            "year": 2025,
            "month": 7
          },
          {
            "_id": "67016b2d831c71f5a2733022",
            "property": "Oleron Villa",
            "start": "2025-07-28T00:00:00.000Z",
            "end": "2025-08-02T00:00:00.000Z",
            "booked": true,
            "price": 2100,
            "archived": false,
            "year": 2025,
            "month": 7
          }
        ]
      },
      {
        "month": "August",
        "monthFR": "Août",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733023",
            "property": "Oleron Villa",
            "start": "2025-08-02T00:00:00.000Z",
            "end": "2025-08-09T00:00:00.000Z",
            "booked": true,
            "price": 2100,
            "archived": false,
            "year": 2025,
            "month": 8
          },
          {
            "_id": "67016b2d831c71f5a2733024",
            "property": "Oleron Villa",
            "start": "2025-08-09T00:00:00.000Z",
            "end": "2025-08-16T00:00:00.000Z",
            "booked": true,
            "price": 2100,
            "archived": false,
            "year": 2025,
            "month": 8
          },
          {
            "_id": "67016b2d831c71f5a2733025",
            "property": "Oleron Villa",
            "start": "2025-08-16T00:00:00.000Z",
            "end": "2025-08-23T00:00:00.000Z",
            "booked": true,
            "price": 2100,
            "archived": false,
            "year": 2025,
            "month": 8
          },
          {
            "_id": "67016b2d831c71f5a2733026",
            "property": "Oleron Villa",
            "start": "2025-08-23T00:00:00.000Z",
            "end": "2025-08-30T00:00:00.000Z",
            "booked": true,
            "price": 1550,
            "archived": false,
            "year": 2025,
            "month": 8
          },
          {
            "_id": "67016b2d831c71f5a2733027",
            "property": "Oleron Villa",
            "start": "2025-08-30T00:00:00.000Z",
            "end": "2025-09-06T00:00:00.000Z",
            "booked": false,
            "price": 1300,
            "archived": false,
            "year": 2025,
            "month": 8
          }
        ]
      },
      {
        "month": "September",
        "monthFR": "Septembre",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733028",
            "property": "Oleron Villa",
            "start": "2025-09-06T00:00:00.000Z",
            "end": "2025-09-13T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 9
          },
          {
            "_id": "67016b2d831c71f5a2733029",
            "property": "Oleron Villa",
            "start": "2025-09-13T00:00:00.000Z",
            "end": "2025-09-20T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 9
          },
          {
            "_id": "67016b2d831c71f5a273302a",
            "property": "Oleron Villa",
            "start": "2025-09-20T00:00:00.000Z",
            "end": "2025-09-27T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 9
          },
          {
            "_id": "67016b2d831c71f5a273302b",
            "property": "Oleron Villa",
            "start": "2025-09-27T00:00:00.000Z",
            "end": "2025-10-04T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 9
          }
        ]
      },
      {
        "month": "October",
        "monthFR": "Octobre",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a273302c",
            "property": "Oleron Villa",
            "start": "2025-10-04T00:00:00.000Z",
            "end": "2025-10-11T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 10
          },
          {
            "_id": "67016b2d831c71f5a273302d",
            "property": "Oleron Villa",
            "start": "2025-10-11T00:00:00.000Z",
            "end": "2025-10-18T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 10
          },
          {
            "_id": "67016b2d831c71f5a273302e",
            "property": "Oleron Villa",
            "start": "2025-10-18T00:00:00.000Z",
            "end": "2025-10-25T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 10
          },
          {
            "_id": "67016b2d831c71f5a273302f",
            "property": "Oleron Villa",
            "start": "2025-10-25T00:00:00.000Z",
            "end": "2025-11-01T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 10
          }
        ]
      },
      {
        "month": "November",
        "monthFR": "Novembre",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733030",
            "property": "Oleron Villa",
            "start": "2025-11-01T00:00:00.000Z",
            "end": "2025-11-08T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 11
          },
          {
            "_id": "67016b2d831c71f5a2733031",
            "property": "Oleron Villa",
            "start": "2025-11-08T00:00:00.000Z",
            "end": "2025-11-15T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 11
          },
          {
            "_id": "67016b2d831c71f5a2733032",
            "property": "Oleron Villa",
            "start": "2025-11-15T00:00:00.000Z",
            "end": "2025-11-22T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 11
          },
          {
            "_id": "67016b2d831c71f5a2733033",
            "property": "Oleron Villa",
            "start": "2025-11-22T00:00:00.000Z",
            "end": "2025-11-29T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 11
          },
          {
            "_id": "67016b2d831c71f5a2733034",
            "property": "Oleron Villa",
            "start": "2025-11-29T00:00:00.000Z",
            "end": "2025-12-06T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 11
          }
        ]
      },
      {
        "month": "December",
        "monthFR": "Décembre",
        "bookings": [
          {
            "_id": "67016b2d831c71f5a2733035",
            "property": "Oleron Villa",
            "start": "2025-12-06T00:00:00.000Z",
            "end": "2025-12-13T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 12
          },
          {
            "_id": "67016b2d831c71f5a2733036",
            "property": "Oleron Villa",
            "start": "2025-12-13T00:00:00.000Z",
            "end": "2025-12-20T00:00:00.000Z",
            "booked": false,
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 12
          },
          {
            "_id": "67016b2d831c71f5a2733037",
            "property": "Oleron Villa",
            "start": "2025-12-20T00:00:00.000Z",
            "end": "2025-12-27T00:00:00.000Z",
            "booked": true,
            "notes": "Morgans?",
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 12
          },
          {
            "_id": "67016b2d831c71f5a2733037",
            "property": "Oleron Villa",
            "start": "2025-12-27T00:00:00.000Z",
            "end": "2026-01-03T00:00:00.000Z",
            "booked": true,
            "notes": "Morgans?",
            "price": 1200,
            "archived": false,
            "year": 2025,
            "month": 12
          }
        ]
      }
    ],
    "year": 2025
  }
];